<template>
  <div class="dictionary-table">
    <div class="search-wrapper">
      <search @search="handleSearch" @reset="handleRest" />
    </div>
    <div class="table-wrapper" v-loading="loading">
      <vxe-grid
        border
        :data="dataSource"
        :columns="tableColumn"
        size="small"
        v-bind="tableOptions"
        @checkbox-change="handleCheckboxChange"
        @checkbox-all="handleCheckboxAll"
      >
        <template #toolbar_buttons>
          <div style="padding: 0 16px">
            <el-button size="small" type="primary" @click="handleAdd">新增</el-button>
            <!-- <el-button size="small" type="primary">导入</el-button> -->
            <!-- <el-button size="small" type="default" @click="handleExport">导出</el-button> -->
          </div>
        </template>
        <template #operate="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleShowDeleteModal(row)">删除</el-button>
        </template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="total,prev, pager, next ,sizes,jumper"
            :total="iPagination.total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </div>
    <edit-dictionary-modal ref="modalForm" @ok="modalFormOk" />
  </div>
</template>

<script>
import IPagination from '@/components/i-pagination/index.vue';
import TableListMinix from '@/minixs/TableListMinix';
import EditDictionaryModal from './EditDictionaryModal.vue';
import Search from './search/index.vue';
import { exportAction } from '@/api/manage';

export default {
  name: 'DictionaryTable',
  mixins: [TableListMinix],
  props: {
    parentId: {
      type: String,
      default: '',
      required: false
    }
  },
  queryParams: {
    parentId: ''
  },
  components: {
    IPagination,
    EditDictionaryModal,
    Search
  },
  data() {
    return {
      modalForm: 'modalForm',
      url: {
        list: 'misc-web-api/admin/dictionary/list',
        singleDeleteUrl: 'misc-web-api/admin/dictionary/del'
      },
      tableColumn: [
        { type: 'checkbox', width: 50, fixed: 'left' },
        // { field: 'dictionaryId', title: '字典ID', fixed: 'left', width: 100 },
        { field: 'dictionaryName', title: '名称', width: 300, showOverflow: 'tooltip' },
        { field: 'dictionaryKey', title: '键', with: 200, showOverflow: 'tooltip' },

        { field: 'dictionaryValue', title: '值', width: 300, showOverflow: 'tooltip' },

        { field: 'sortNum', title: '排序数字' },
        { title: '操作', slots: { default: 'operate' }, fixed: 'right' }
      ],
      tableOptions: {
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons'
          }
        },
        checkboxConfig: {
          // checkField:'rankId',
          checkRowKeys: [],
          reserve: true // 保留勾选状态
        }
      }
    };
  },
  watch: {
    parentId: {
      handler(newVal) {
        this.queryParams.parentId = newVal;
        this.loadData();
      },
      immediate: true
    }
  },
  methods: {
    handleAdd() {
      if (!this.parentId) {
        this.$message.error('请选择字典类型');
        return;
      }
      this.$refs.modalForm.form.parentId = this.parentId;
      this.$refs.modalForm.add();
    },
    handleShowDeleteModal(row) {
      const that = this;
      const params = {
        data: { dictionaryId: row.dictionaryId }
      };
      const message = '确定要删除吗？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.handleSingleDelete(params);
        })
        .catch(() => {});
    },
    async handleExport() {
      if (this.checkedRows.length === 0) {
        this.$message.error('至少选择一项');
        return;
      }
      console.log(this.checkedRows);
      const ids = this.checkedRows.map((item) => item.dictionaryId);
      const params = {
        data: {
          ids
        }
      };
      const res = await exportAction('misc-web-api/admin/dictionary/export', params);
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      });
      const link = document.createElement('a');
      link.style.display = 'none';
      // link.setAttribute('download', `字典类表.xlsx`);
      const objectUrl = URL.createObjectURL(blob);

      link.href = objectUrl;
      link.click();
      URL.revokeObjectURL(objectUrl);
    }
  }
};
</script>

<style scoped lang="less">
.dictionary-table {
  flex: 1;
  .search-wrapper {
    background-color: #fff;
    padding: 10px 16px 0 16px;
  }
}
</style>
